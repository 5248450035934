import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import * as BsIcons from 'react-icons/bs';
import logo from 'assets/mikla_transparent_logo.png';

const Sidebar = ({ open, setOpen }) => {
  const sidebarRef = useRef(null);

  // useEffect hook is used to manage the overflow and height styles of the body and html elements

  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'scroll';
      document.documentElement.style.overflow = 'hidden';
      if (sidebarRef.current) {
        sidebarRef.current.scrollTop = 0;
      }
    } else {
      document.body.style.overflow = 'auto';
      document.documentElement.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
      document.documentElement.style.overflow = 'auto';
    };
  }, [open]);

  // Handler functions to manage the overlay click and sidebar click
  const handleOverlayClick = e => {
    setOpen(false);
  };

  const handleSidebarClick = e => {
    e.stopPropagation();
  };

  // Handler function to manage the link click, closes the sidebar and scrolls to the top of the page
  const handleLinkClick = () => {
    setOpen(false);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <BackgroundOverlay open={open} onClick={handleOverlayClick}>
      <StyledSidebar ref={sidebarRef} open={open} onClick={handleSidebarClick}>
        <SideBarContainer>
          <Column>
            <Link to='/' onClick={handleLinkClick}>
              <LogoWrapper>
                <Logo src={logo} alt='miklagård logo' />
              </LogoWrapper>
            </Link>
            <SidebarNav>
              <SidebarLinks>
                <li>
                  <Divider />
                </li>
                {/* <li>
                  <SidebarLink to='/om-oss' onClick={handleLinkClick}>
                    om miklagård
                  </SidebarLink>
                </li> */}
                {/* <li>
                  <Divider />
                </li> */}
                <li>
                  <SidebarLink to='/meny' onClick={handleLinkClick}>
                    grill & meze
                  </SidebarLink>
                </li>
                <li>
                  <SidebarLink to='/takeaway' onClick={handleLinkClick}>
                    pizzabutik
                  </SidebarLink>
                </li>
                {/* <li>
                  <Divider />
                </li> */}
                <li>
                  <SidebarLink to='/catering' onClick={handleLinkClick}>
                    catering
                  </SidebarLink>
                </li>
                <li>
                  <Divider />
                </li>
              </SidebarLinks>
            </SidebarNav>
          </Column>
          <Column>
            <SocialLinks>
              <SocialLink
                href='https://www.instagram.com/miklagarduppsala'
                target='_blank'
              >
                <BsIcons.BsInstagram />
              </SocialLink>
              <SocialLink
                href='https://www.facebook.com/miklagarduppsala'
                target='_blank'
              >
                <BsIcons.BsFacebook />
              </SocialLink>
            </SocialLinks>
            <Divider />
            <OpeninghoursWrapper>
              <h4>Öppettider</h4>
              <table style={tableStyles}>
                <tbody>
                  <tr>
                    <td>Måndag - Torsdag</td>
                    <td>16:00 - 21:00</td>
                  </tr>
                  <tr>
                    <td>Fredag</td>
                    <td>16:00 - 22:00</td>
                  </tr>
                  <tr>
                    <td>Lördag</td>
                    <td>12:00 - 22:00</td>
                  </tr>
                  <tr>
                    <td>Söndag</td>
                    <td>12:00 - 21:00</td>
                  </tr>
                </tbody>
              </table>
              {/* <ContactInfo>
                <h4>kontakta oss</h4>
                <a href='tel:018-508800'>018-508800</a>
                <a href='mailto:info@miklagarduppsala.se'>
                  info@miklagarduppsala.se
                </a>
              </ContactInfo> */}
            </OpeninghoursWrapper>
          </Column>
        </SideBarContainer>
      </StyledSidebar>
    </BackgroundOverlay>
  );
};

const tableStyles = {
  width: '100%',
  color: '#D7D7D7',
};

const StyledSidebar = styled.aside`
  display: grid;
  place-items: center;
  width: 100%;
  max-height: 100vh;
  background-color: ${({ theme }) => theme.colors.secondary};
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  transition: transform 0.3s ease-in-out;
  -webkit-transition: transform 0.3s ease-in-out;
  -moz-transition: transform 0.3s ease-in-out;
  -ms-transition: transform 0.3s ease-in-out;
  -o-transition: transform 0.3s ease-in-out;
  transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(-100%)')};
  overflow-y: scroll;
  /* -webkit-overflow-scrolling: touch; */
  height: 100%;
  @media screen and (min-width: 768px) {
    width: 50%;
  }

  @media screen and (min-width: 1024px) {
    width: 30%;
  }
`;

const BackgroundOverlay = styled.div`
  visibility: ${({ open }) => (open ? 'visible' : 'hidden')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  -webkit-transition: background-color 0.3s linear;
  -ms-transition: background-color 0.3s linear;
  transition: background-color 0.3s linear;
  background-color: ${({ open }) =>
    !open ? 'transparent' : 'rgba(0, 0, 0, 0.5)'};
  transition: 0.3s all;

  // Add the following lines
  /* overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
  } */
`;

const SideBarContainer = styled.div`
  height: 70vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: space-around; */
  align-items: center;
  z-index: 3;
`;

const Column = styled.div`
  width: 100%;
`;
const Logo = styled.img`
  width: 100%;
`;

const LogoWrapper = styled.div`
  max-width: 5rem;
  margin: 0 auto;
`;

const SidebarNav = styled.nav`
  width: 100%;
`;

const SidebarLinks = styled.ul`
  list-style: none;
  padding-left: 0;
  width: 100%;

  li {
    text-align: center;
    padding: 0.5rem;
  }
`;
const SidebarLink = styled(Link)`
  text-transform: uppercase;
  font-size: clamp(1.2rem, 1vw, 1.8rem);
  color: ${({ theme }) => theme.colors.accentColor2};
`;

const Divider = styled.hr`
  margin: 10px auto;
  height: 1px;
  border: none;

  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    #9aaabf,
    rgba(255, 255, 255, 0)
  );
  width: 80%;
`;

const SocialLinks = styled.div`
  font-size: 1.5rem;
  text-align: center;
  padding-bottom: 1rem;
`;

const SocialLink = styled.a`
  color: ${({ theme }) => theme.colors.accentColor2};
  &:first-child {
    margin-right: 0.5rem;
  }
`;

const OpeninghoursWrapper = styled.div`
  margin: 0 auto;
  padding: 1rem;
  width: 70%;

  h4 {
    font-size: 1.3rem;
    margin-bottom: 1rem;
    color: ${({ theme }) => theme.colors.textColor};
  }
`;

export default Sidebar;
