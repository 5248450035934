import { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import styled from "styled-components";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";

import { ThemeProvider } from "styled-components";
import { GlobalStyles } from "styles/global";
import theme from "styles/theme";

import Home from "pages/Home";
import CateringPage from "pages/catering-page/catering-page";
import Error from "pages/Error";
// import TakeawayPage from "pages/TakeawayPage";
import Menu from "pages/DineInPage";
import AboutPage from "pages/AboutPage";

import SidebarBurger from "components/SidebarBurger";
import Footer from "components/Footer";
import Sidebar from "components/Sidebar";
import Loader from "components/Loader";
// import CookieBanner from 'components/_Cookie/CookieBanner';
import CookieBanner from "components/CookieBanner";
import useInitializeAnalytics from "hooks/useInitializeAnalytics";

import PrivacyPolicy from "pages/PrivacyPolicy";
import ModalContactDiv from "components/ModalContactDiv";

function App() {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useInitializeAnalytics();

  const getRandomTimeout = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, getRandomTimeout(1800, 2100));

    // Clean up the timeout on component unmount
    return () => clearTimeout(timer);
  }, []);

  // useEffect(() => {
  //   initilizeGA();
  // }, []);

  const handleSidebarClick = () => {
    setIsOpen(!isOpen);
  };
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <GlobalStyles open={isOpen} />
        <SpinnerContainer isLoading={isLoading}>
          <Loader />
        </SpinnerContainer>
        <Wrapper isLoading={isLoading}>
          <div
            style={{
              position: "fixed",
              top: "1.5rem",
              left: "1.5rem",
              zIndex: "100",
            }}
          >
            <SidebarBurger
              open={isOpen}
              setOpen={setIsOpen}
              onClick={handleSidebarClick}
            />
          </div>
          <Sidebar open={isOpen} setOpen={setIsOpen} />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/meny" element={<Menu />} />
            {/* <Route path='/meny' element={<OnsiteMenu />} />*/}
            {/* <Route path='/takeaway' element={<TakeawayPage />} /> */}
            <Route path="/catering" element={<CateringPage />} />
            <Route path="/om-oss" element={<AboutPage />} />
            {/* <Route path='/privacy-policy' element={<PrivacyPolicy />} /> */}
            <Route path="*" element={<Error />} />
          </Routes>
          <Footer />
          {/* <ModalContactDiv /> */}
          <CookieBanner />
          <ToastContainer
            autoClose={2000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick={true}
            rtl={false}
            pauseOnFocusLoss={false}
            draggable={false}
            pauseOnHover={false}
            className="toast-container"
            toastClassName={({ type }) => {
              switch (type) {
                case toast.TYPE.SUCCESS:
                  return "toast-success";
                case toast.TYPE.ERROR:
                  return "toast-error";
                default:
                  return "";
              }
            }}
          />
        </Wrapper>
      </ThemeProvider>
    </BrowserRouter>
  );
}

const Wrapper = styled.div`
  opacity: ${({ isLoading }) => (isLoading ? "0.5" : "1")};
  pointer-events: ${({ isLoading }) => (isLoading ? "none" : "auto")};
  filter: ${({ isLoading }) => (isLoading ? "blur(5px)" : "none")};
  transition: opacity 0.5s, filter 0.5s;
  z-index: 101;

  .toast-container {
    /* Add custom styles here */
    font-family: Arial, sans-serif;
    font-size: 1rem;
  }

  .toast-success {
    background-color: #7f8c8d;
    color: #1a1a1a;
    padding: 1rem;
    border-radius: ${({ theme }) => theme.borderRadius};
  }

  .toast-error {
    background-color: #e74c3c;
    color: #d7d7d7;
    padding: 1rem;
    border-radius: ${({ theme }) => theme.borderRadius};
  }
`;

const SpinnerContainer = styled.div`
  display: ${({ isLoading }) => (isLoading ? "flex" : "none")};
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export default App;
