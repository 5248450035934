import React from 'react';
import { Sticky } from 'react-sticky';

import {
  MenuItem,
  SubCategory,
  Item,
  Header,
  Description,
  Name,
  Price,
  CategoryDesc,
  ItemWrapper,
  StyledStickyContainer,
  CategoryCard,
} from '../StyledMenuElements';

const DineInItems = ({ items }) => {
  return (
    <>
      {items.map(menuItem => {
        const {
          subCategory,
          categoryId,
          description,
          takeawayDesc,
          takeawayDesc2,
          takeawayDesc3,
        } = menuItem;

        return (
          <StyledStickyContainer key={categoryId}>
            <Sticky>
              {({ style }) => (
                <CategoryCard style={style}>
                  <SubCategory>{subCategory}</SubCategory>
                  {description && <CategoryDesc>{description}</CategoryDesc>}
                  {takeawayDesc && <CategoryDesc>{takeawayDesc}</CategoryDesc>}
                  {takeawayDesc2 && (
                    <CategoryDesc>{takeawayDesc2}</CategoryDesc>
                  )}
                  {takeawayDesc3 && (
                    <CategoryDesc>{takeawayDesc3}</CategoryDesc>
                  )}
                </CategoryCard>
              )}
            </Sticky>
            <MenuItem>
              <ItemWrapper>
                {menuItem.items.map(({ id, name, desc, price }) => {
                  return (
                    <Item key={id}>
                      <Header>
                        <Name>{name}</Name>
                        <Price>{price}:-</Price>
                      </Header>
                      <Description>{desc}</Description>
                    </Item>
                  );
                })}
              </ItemWrapper>
            </MenuItem>
          </StyledStickyContainer>
        );
      })}
    </>
  );
};

export default DineInItems;
