import { Link } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import styled from 'styled-components';

import pic1 from 'assets/images/about/1.jpg';
import pic2 from 'assets/images/about/2.jpg';
import pic3 from 'assets/images/about/3.jpeg';

const About = () => {
  return (
    <AboutContainer id='about'>
      <AboutRow>
        <Column1>
          <Carousel
            animationHandler='fade'
            autoPlay
            infiniteLoop={true}
            showArrows={false}
            showThumbs={false}
            showStatus={false}
            swipeable={false}
            showIndicators={false}
            interval={4500}
            transitionTime={2300}
            stopOnHover={true}
            className='carousel'
          >
            <ImgWrap>
              <Img alt='grillrätt' src={pic1} />
            </ImgWrap>
            <ImgWrap>
              <Img alt='olika meze på bord' src={pic2} />
            </ImgWrap>
            <ImgWrap>
              <Img alt='inredning med uppdukade bord' src={pic3} />
            </ImgWrap>
          </Carousel>
        </Column1>
        <Column2>
          <Heading>Upptäck det turkiska köket på Miklagård</Heading>
          <p>
            Om du letar efter en turkisk restaurang som betonar användningen av
            färska råvaror och hög kvalitet, då är Miklagård stället för dig.
          </p>
          <p>
            Vår meny är full med turkiska specialiteter som grillade kötträtter
            och meze, samt veganska och vegetariska alternativ.
          </p>
          <p>
            Och om du är sugen på något annat, har vi också en pizzeria med en
            separat ingång där du kan njuta av traditionell surdegspizza och
            andra snabbbitar.
          </p>
          <p> Vi hoppas att vi får se dig på Miklagård snart!</p>
          <Link to='/meny'>
            <button>grill & meze meny</button>
          </Link>
          {/* <OpeningHoursContainer>
              <Header>Öppettider</Header>
              <Table>
                <TableBody>
                  <TableRow>
                    <HeaderCell>Måndag - Torsdag</HeaderCell>
                    <DataCell>16.00 - 22.00</DataCell>
                  </TableRow>
                  <TableRow>
                    <HeaderCell>Fredag</HeaderCell>
                    <DataCell>16.00 - 23.00</DataCell>
                  </TableRow>
                  <TableRow>
                    <HeaderCell>Lördag</HeaderCell>
                    <DataCell>12.00 - 23.00</DataCell>
                  </TableRow>
                  <TableRow>
                    <HeaderCell>Söndag</HeaderCell>
                    <DataCell>12.00 - 22.00</DataCell>
                  </TableRow>
                </TableBody>
              </Table>
            </OpeningHoursContainer> */}
        </Column2>
      </AboutRow>
    </AboutContainer>
  );
};

const AboutContainer = styled.section`
  padding-top: 4rem;

  button {
    margin-top: 2rem;
  }
`;

const AboutRow = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2rem 4rem;
  align-items: center;
  justify-items: center;
  @media screen and (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const Column1 = styled.div`
  position: relative;
  z-index: 1;
  .carousel {
    z-index: 2;
    clip-path: inset(0% 0 0% 0 round 10px);
    max-width: 25rem;
  }
`;

const Column2 = styled.div``;

const ImgWrap = styled.div`
  height: 100%;
`;

const Img = styled.img`
  width: 100%;
`;

const Heading = styled.h3`
  color: ${({ theme }) => theme.primaryDark};
`;

const Subtitle = styled.p`
  line-height: 24px;
  font-size: 18px;
  &:first-letter {
    text-transform: capitalize;
  }
`;

const Table = styled.table`
  width: 100%;
  padding: 10px;
  border-radius: 0.5rem;
  background-color: lightGray;
  margin-bottom: 1rem;
`;

const Header = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 5px;
`;

const TableBody = styled.tbody``;

const TableRow = styled.tr``;

const HeaderCell = styled.th`
  text-align: left;
`;

const DataCell = styled.td`
  text-align: right;
`;

export default About;
